import Heading from "../Paragraph/Heading"
import Paragraph from "../Paragraph/Paragraph"
import TopSpacing from "../Section/TopSpacing"
import Callout from "../Slide1/TopPanel/Callouts/Callout"
import InterventionSites from "./SanitationMap1"
import {useState,useEffect} from 'react'
import "./Sanitation.css"
const Water=()=>{
    const [villages,setvillages]=useState([])
    const [households,sethouseholds]=useState([])
    const [compliant,setcompliant]=useState(0)
    const [people,setpeople]=useState(0)
    const fetchVillages=()=>{
        fetch('/api/v1/fetchVillages', {method:'GET'}).then(villages=>villages.json()).then(villages=>{
            setvillages(villages.villages)
        })
    }
    const fetchHouseholds=()=>{
        fetch('/api/v1/getallhouseholds', {method:'GET'}).then(villages=>villages.json()).then(households=>{
            sethouseholds(households.households)
            const completedHouseholds = households.households.filter(item => item.currentPercent === 100).length;
            setcompliant(completedHouseholds/households.households.length*100)
        })
    }
    const fetchCompleteHouseholds=()=>{
        fetch('/api/v1/getcompletehouseholds', {method:'GET'}).then(villages=>villages.json()).then(households=>{
            const people=households.households.reduce((accum,curr)=>{
                let people=accum+((curr.females+curr.males)||0)
                return people
            },0)
            setpeople(people)
        })
    }
    useEffect(()=>{
        fetchVillages()
        fetchHouseholds()
        fetchCompleteHouseholds()
    },[])
    return (
        <div className="water-slide-div">
            <TopSpacing/>
            {/* <Heading title={"100% WASH Sanitation Interventions"}/>
            <Paragraph content={"Part of our WASH interventions within the 100% WASH project is improving the sanitation coverage through Community Led Total Sanitation(CLTS) interventions. Our aim is to use water as an entry point in to the communities for the sanitation interventions and as such, we target communities around our water projects with CLTS interventions. Below is a map showing the communities we are currently implementing in and the stage they are in"}/> */}
            <div className="water-page-callout-div">
            <Callout datasource={villages.length} description={"Total Villages currently being tracked"}/>
            <Callout datasource={households.length} description={"Total Households currently being tracked"}/>
            <Callout datasource={`${Math.floor(compliant)}%`} description={"of the households have built basic latrines within homestead"}/>
            <Callout datasource={people} description={"People have gained access to basic sanitation facilities"}/>
            </div>
            <InterventionSites households={households}/>
        </div>
    )
}
export default Water