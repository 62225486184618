import WardSource from "../Slide1/Layers/WardSource";
import ReactMapGL from "react-map-gl";

import SchoolPoints from "../Slide1/CentralPanel/MapTypes/Points";
import { useState,useCallback,useRef } from "react";
const InterventionSites=()=>{
    const [hoveredFeatureId, setHoveredFeatureId] = useState(null);
  const mapRef = useRef(null);
  let style="mapbox://styles/mapbox/dark-v11"

  const [viewport, setViewport] = useState({
    latitude: 0.673547,
    longitude: 38.062197,
    zoom: 6,
    width: "100%",
    height: "100%",
  });

  const handleViewportChange = (newViewport) => {
    setViewport({ ...viewport, ...newViewport.viewState });
  };

  const moveEnd = () => {
    setViewport({
      latitude: -1.3367309,
      longitude: 35.3244957,
      zoom: 8.5,
      width: "100%",
      height: "100%",
    });
  };

  const handleHover = useCallback((event) => {
    const map = event.target;  // The map instance
    const { point } = event;   // The x, y point on the screen
  
    // Query features under the cursor at the given point
    const features = map.queryRenderedFeatures(point, {
      layers: ['vector-layer1']  // Specify the layer ID(s) you want to query
    });
  
    // If there are features at the point
    if (features.length > 0) {
      const hoveredFeature = features[0];  // Get the first feature (or handle multiple)
  
      // Check if the feature is different from the currently hovered feature
      if (hoveredFeature.id !== hoveredFeatureId) {
        // Reset the hover state of the previous feature
        if (hoveredFeatureId) {
          map.setFeatureState(
            { source: 'my-vector-source', id: hoveredFeatureId, sourceLayer: "N2S_wards-asr6x8" },
            { hover: false }
          );
        }
  
        // Set the hover state of the new feature
        setHoveredFeatureId(hoveredFeature.id);
        map.setFeatureState(
          { source: 'my-vector-source', id: hoveredFeature.id, sourceLayer: "N2S_wards-asr6x8" },
          { hover: true }
        );
      }
    } else if (hoveredFeatureId) {
      // If no feature is found but one was previously hovered, reset it
      map.setFeatureState(
        { source: 'my-vector-source', id: hoveredFeatureId, sourceLayer: "N2S_wards-asr6x8" },
        { hover: false }
      );
      setHoveredFeatureId(null);
    }
  }, [hoveredFeatureId]);

  return (
    <div className="water-page-water-map">
        <ReactMapGL
      {...viewport}
      onMove={handleViewportChange}
    //   onMoveEnd={moveEnd}
      scrollZoom={false}
    //   dragPan={true}
      onMouseMove={handleHover}
      mapboxAccessToken={"pk.eyJ1IjoibWlzY3VpdCIsImEiOiJja3R6dXNrb2QwNHp4MnZvMXRqZGZyemN5In0.kRmmpYfd_OQ0BaAFK8u8pw"}
      mapStyle={style}
      ref={mapRef}
    >
        <WardSource/>
        <SchoolPoints id='w-a-p' />
    </ReactMapGL>
    </div>

  )
}
export default InterventionSites