import logo from "./logo.svg";
import "./App.css";
import Layout from "./Layout";
import Homepage from "./Pages/Homepage/Homepage";
import Paragraph from "./Pages/Paragraph/Paragraph";
import NeedsAssessment from "./Pages/NeedsAssessment/Needs";
import Drawer from "./components/Drawer/Drawer";
import Heading from "./Pages/Paragraph/Heading";
import Slide1 from "./Pages/Slide1/Slide";
import headerimage from './Images/water3.jpg'
import landingimage from './Images/image4.png'
import Slide2 from "./Pages/Slide2/Slide";
import Slide3 from "./Pages/Slide3/Slide";
import ScrollToTop from "./components/ScrollToTop";
import backgroundImage from './Images/water3.jpg'
import Water from "./Pages/Water/Water";
import Sanitation from "./Pages/Sanitation/Sanitation"
import PageHeader from "./Pages/PageHeader/PageHeader";
import PageFooter from "./Pages/PageFooter/PageFooter";
import Project from "./Pages/WaterAccess/ProjectView";
import Dashboard from "./Pages/WaterAccess/Dashboard";
import GanttChart from "./Pages/WaterAccess/Gannt";
import { useEffect, useContext, useState, createContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Section from "./Pages/Section/Section";
import TopSpacing from "./Pages/Section/TopSpacing";
export const slideCountContext=createContext()
function App() {
  let ParagraphContent="babel-preset-react-app is part of the create-react-app project, which is not maintianed anymore. It is thus unlikely that this bug will ever be fixed. Addbabel/plugin-proposal-private-property-in-objec to your devDependencies to work around this error. This will make this message go away."
  // const [currentslideindex,setcurrentslideindex]=useState(0)
  // const slides=[{slide:Slide1},{slide:Slide2},{slide:Slide3}]
  // const [currentslide,setcurrentslide]=useState(slides[currentslideindex])
  // useEffect(()=>{},[currentslide])
  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     switch (event.keyCode) {
  //       case 37:
  //         if(currentslideindex>0){
  //           setcurrentslideindex(currentslideindex-1)
  //         }
  //           break;
  //       case 39:
  //         if(currentslideindex+1<slides.length){
  //           setcurrentslideindex(currentslideindex+1)
  //         }
  //           break;
  //       default:
  //             return
  //   }
  //     console.log(`Key pressed: ${event.key}`);
  //   };
  //   // Add the event listener
  //   window.addEventListener('keydown', handleKeyDown);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, [currentslide]);
  // useEffect(()=>{
  //   setcurrentslide(slides[currentslideindex])
  // },[currentslideindex])
  return (
    <div className="App">
      <Router>
        <ScrollToTop/>
        <Layout>
          <Switch>
            {/* <Route exact path="/">
            <Navbar/>
            <PageHeader image={landingimage}/>
            <Paragraph content={"The 100% WASH project is focused on realizing the human rights to clean drinking water, safe sanitation and desired hygiene practices in underserved rural regions in Kenya. The WASH interventions are essential for a healthy life and a better future. "}/>
            <Paragraph content={"The project aims to deliver direct positive effect on health, education, and economy for women and children WHERE children attend school; dignity, safety, and privacy are restored; waterborne diseases are prevented; women & girls get time for other productive purposes instead of walking miles to fetch water from unsafe water points. The project is currently implemented in rural communities in Narok West and Transmara West sub counties in Narok County."}/>
            <Paragraph content={"The project is currently implemented in rural communities in Narok West and Transmara West sub counties in Narok County."}/>
            <Section/>
            </Route>
            <Route exact path="/water-access"> */}
             {/**<slideCountContext.Provider value={{currentslideindex,setcurrentslideindex}}>
              {<currentslide.slide/>}
              </slideCountContext.Provider>}
            </Route>
            <Route exact path="/sanitation-coverage">
              {/* <Slide /> */}
              {/* <PageHeader image={headerimage}/> */}
              {/* <Heading title={'Introducing 100% WASH Project'}/>
              <Paragraph content={ParagraphContent}/>
              <Paragraph content={ParagraphContent}/>
              <Heading title={'Narok Schools Needs Assessment'}/>
              <Paragraph content={ParagraphContent}/>
              <Slide1/>
              <Paragraph content={ParagraphContent}/>
              <Paragraph content={ParagraphContent}/>
              <Paragraph content={ParagraphContent}/>
              <Heading title={'Where have we intervened?'}/>
              <Paragraph content={ParagraphContent}/>
              <Slide2/>
              <Paragraph content={ParagraphContent}/>
              <Paragraph content={ParagraphContent}/>
              <Paragraph content={ParagraphContent}/>
              <Paragraph content={ParagraphContent}/>
              <Paragraph content={ParagraphContent}/> */}
              {/* <Navbar/>
              <Water/>
            </Route> */}
            <Route exact path="/">
            <Navbar/>
              <NeedsAssessment />
            </Route>
            <Route exact path="/sanitation-coverage">
            <Navbar/>
              <Sanitation />
            </Route>
            <Route exact path="/water-access">
            <Navbar/>
              <Water />
            </Route>
            <Route exact path="/needs-assessment">
            <Navbar/>
              <NeedsAssessment />
            </Route>
            <Route exact path="/gantt">
            <Navbar/>
              <Dashboard />
            </Route>
            <Route exact path={`/water-access/site/:id/:sitename`}>
              <Navbar/>
              <TopSpacing/>
              <GanttChart />
            </Route>
            <Route exact path={`/water-access/sensor-readings`}>
            <Slide3/>
            </Route>
          </Switch>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
